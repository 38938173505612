body {
  margin: 0;
  background: linear-gradient(220deg, #2c3139 -2.06%, #16181d 102.08%);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Scrollbar*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgb(113, 114, 114);
}

::-webkit-scrollbar-thumb {
  background-color: #418e97;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2cbcc4;
}


.flex ul {
  display: flex;
  list-style: none;
  margin-right: 20px;

}

.flex li {
  margin-right: 20px;
  
}

.header {
  background-color: #282c34;
  color: white;
  padding: 0.2rem;
  position: sticky;
  top: 0;
  align-items: center;
  z-index: 100;
}

.header li {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #524f4f;
  text-decoration: none;
  font-weight: bold;
  box-sizing: border-box;
}

.header li:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}
.header div {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

header nav ul li a {
  color: #ffffff;
  text-decoration: none;
}

header nav ul li a:hover {
  color: #34bcc4; 
}

.tradingview-widget {
  width: 100%;
  height: 690px;
}

.vertical-menu {
  background-color: #282c34;
  height: 660px;
  width: 60px;
  float: right;
  margin-left: 20px;
  top: 40px;
  box-shadow:  0 2px 4px rgb(23, 23, 23);
  z-index: 99;
}

.vertical-menu ul {
  padding: 0;
  margin-top: 0px;
}

.vertical-menu ul li {
  display: flex;
  flex-direction: column;
  height: 33%;
  margin: 0
}

.icon-color {
  color: #34bcc4 
}

.menu-button {
  background-color: transparent;
  border: none;
  padding: 15px;
  cursor: pointer
}

.menu-button:hover {
  transition: 0.3s ease;
  background-color: #595d65
}

.panel-menu {
  background-color: #050c1ea5;
  width: 400px;
  height: 690px;
  position: relative;
  z-index: 70;
}

.watchlist-container__widget{
  position: absolute;
  z-index: 0;
}

.main-div {
  display: flex;
  align-items: flex-start
}

.search-header {
  background-color: #1f2e44;
  padding: 10px;
  height: 19px;
}

.search-header input[type="text"] {
  width: 30%;
  height: 8px;
  padding: 8px;
  border-radius: 5px;
  border: none;
  border-color: #000000;
  background-color: #2e37448c;
  margin-left: 150px;
  color:#34bcc4 ;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5); 
}

.search-input::placeholder {
  color: #31698fba;
}

.search-input:focus {
  outline: none;
  border: 2px solid #000000; 
}

.basic-header {
  margin-top: 70%;
  background-color: #1f2e44;
  padding: 10px
}

.search-header h1,
.basic-header h1 {
  font-size: 15px;
  display: inline;
  color: #34bcc4 
}

.search-header,
.basic-header{
  box-shadow:  0 2px 4px rgb(23, 23, 23)
}

Footer{
  text-align: center;
  height: 100px;
  width: 100%;
}

Footer h3{
  margin-top: auto;
  margin-bottom: auto;
  color: #34bcc4 ;
  font-size: 25px;
}

Footer p{
  color: #c0c0c1;
  font-family: sans-serif;
  margin: 10px;

}

.main-div-content {
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

.footer-logo{
  width: 320px;
  height: 120px;
  padding: 10px;
  margin-bottom: 40px;
}

.login-page{
  height: 650px;
  width: 50%;
  background-color: #282c34;
  color: #ffffff;
  margin-top: 30px;
  margin-left: 200px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.our-company{
  background: linear-gradient(to right, #b2f1ffbb, #5cb6b9 , #34b4c4 , #62b3b9b6);
  height: 650px;
  width: 50%;
  color: #2d2d2d;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-right: 200px;
  margin-bottom: 50px;
  display: flex;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.div-patterns {
  display: flex;
  height: 300px; 
}

.filter-header {
  display: flex;
  background-color: #131722;
  height: 50px;
  width: 100%;
}

input[type="text"]::placeholder {
  text-align: center;
}

input[type="text"]{
  margin: 10px;
}

.searchInput{
  box-shadow: inset 0 0 3px rgb(19, 19, 19);
  height: 30px;
  width: 20%;
  border: none;
  border-radius: 10px;
  background: #828B9E;
  margin: 5px;
  color: #34bcc4 ;
}

.filter-header button{
  height: 30px;
  min-width: 80px;
  color: white;
  background-color: #828B9E;
  border: none;
  margin: 10px;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.473);
}

.filter-header button:hover{
  transition: 0.3s ease;
  color: #ffffff;
  background-color: #34bcc4 ;
}

.description{
  background-color: #33bcbc;
  color: #ffffff;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.description h4{
  text-align: center;
  justify-content: center;
}

.notification-panel{
  color: #ffffff;
  background-color: #131722;
  margin: 0 auto;
  height: auto;
  width: 80%;
  display: grid;
  place-items: center;
}

  
.notification-panel tr{
  color: #34bcc4 ;
  height: 40px;
  margin: auto;
  display: flex;
  place-items: center;
  gap: 70px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: medium;
}

.notification-items{
  background-color: #3B4558;
  display: flex;
  place-items: center;
  height: 550px;
  width: 100%;
}

.chart-ideas{
  height: auto;
}

.menu button{
  color: #b3daf5;
  height: 40px;
  width: auto;
  background-color: #1f3b44;
  margin: 10px;
  border: none;
  border-radius: 3px;
}

.menu button:hover{
  transition: 0.3s ease;
  background-color: #34bcc4 91;
}

.panel-account input{
  color: #b3daf5;
  background-color: #2058698c;
  border: none;
  border-radius: 3px;
  height: 25px;
  width: 100%;
  margin: 10px;
}

.panel-account button{
  background-color: #34bcc4 ;
  color: #e6e5e5;
  font-size: medium;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 10px;
  cursor: pointer;
}

.account-settings{
  background-color: #1f3d44;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 25%;
  margin: 5px;
  margin-top: 20%;
  border-radius: 5px;
  justify-content: center;
}

.settings-panel label{
  height: auto;
  width: 100%;
  margin: 10px;
}

.settings-panel input{
  color: #34bcc4 ;
  margin: 10px;
}

.settings-panel{
  color: #b3daf5;
  display: flex;
  flex-direction: column;
}

.dropdown-content{
  position: absolute;
  right: 1%;
  left: 68%;
  background-color: #414048;
  color: #d9e9f6;
  width: auto;
  height: 400px;
  overflow-y: scroll; 
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.473);
  z-index: 99;
  box-sizing: border-box
}

.notifications-dropdown-content{
  position: absolute;
  background-color: #414048;
  color: #d9e9f6;
  width: 30%;
  height: 400px;
  overflow-y: scroll; 
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.473);
  z-index: 99;
}

.checkbox-container {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  padding: 5px;
  width: 33.3%;
  font-size: medium;
}

.filters-checkbox input[type="checkbox"] {
  margin: 5px;
}

.notifications-filter-header {
  display: flex;
  justify-content: center;
  background-color: #131722;
  height: 50px;
  width: 100%;
  gap: 20px;
}

.notifications-filter-header button{
  height: 30px;
  min-width: 80px;
  width: auto;
  color: white;
  background-color: #828B9E;
  border: none;
  margin: 10px;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.473);
}

.notifications-filter-header button:hover{
  transition: 0.3s ease;
  color: #ffffff;
  background-color: #34bcc4 ;
}

.notifications-options{
  display: flex;
  min-width: 300px;
  color: #fff;
  padding: 15px;
}

.notifications-options span{
  width: 50%;
  margin-left: 10px;
}

.notifications-options a{
  color: #2cbcc4;
  text-decoration: none;
}

.setup-dropdown-content{
  font-size: medium;
  position: absolute;
  background-color: #414048;
  color: #d9e9f6;
  width: auto;
  min-height: 100px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.473);
  z-index: 99;
  box-sizing: border-box
}

.watchlist-dropdown-content{
  position: absolute;
  background-color: #414048;
  color: #d9e9f6;
  width: 30%;
  height: auto;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.473);
  z-index: 99;

}

.header-color {
  background-color: #4c5960; 
  color: white;
  border: none;
}
